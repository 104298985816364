/* eslint-disable max-len */
import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';

@Injectable()
export class SanitizerInterceptor implements HttpInterceptor {
	private readonly unicodeRegex = /[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{2300}-\u{23FF}\u{2B50}-\u{2B55}\u{2B05}-\u{2B07}\u{2934}-\u{2935}\u{2B1B}-\u{2B1C}\u{25AA}-\u{25AB}\u{25FE}-\u{25FF}\u{2190}-\u{21AA}\u{2194}-\u{2199}\u{261D}\u{2620}\u{2622}-\u{2623}\u{2639}-\u{263A}\u{2640}\u{2642}\u{2648}-\u{2653}\u{267B}\u{269B}\u{269C}\u{26A0}-\u{26A1}\u{26AA}-\u{26AB}\u{26B0}-\u{26B1}\u{26BD}-\u{26BE}\u{26C4}-\u{26C5}\u{26CE}-\u{26CF}\u{26D4}\u{26D5}-\u{26D6}\u{26EA}\u{26F0}-\u{26F5}\u{26FD}\u{2702}-\u{27B0}]/gu;
	private sanitize = (req: HttpRequest<any>) => this.sanitizeUnicode(req);
	private sanitizeUnicode = (req: HttpRequest<any>) => req?.body ? req.clone({ body: JSON.parse(this.handleUnicode(JSON.stringify(req.body))) }) : req
	private handleUnicode = (str: string) => str.replace(this.unicodeRegex, '');
	intercept = (req: HttpRequest<any>, next: HttpHandler): any => next.handle(this.sanitize(req));
}
