/* eslint-disable max-len */
import { Answer } from "./Answer";

export class CommonQuestionnaireMapper {
	readonly documentAnswers = ['RF56', 'RF3', 'RF1', 'RF55', 'RF58', 'RF4', 'RF5', 'RF63', 'RF7', 'RF6', 'RF11', 'RF8', 'RF52', 'RF10', 'RF9', 'RF41', 'RF57', 'RF35', 'RF36', 'RF33', 'RF59', 'RF60', 'RF62', 'RF54', 'RF66', 'RF67', 'RF68', 'RF70', 'RF71', 'RF72', 'TR2', 'RF102', 'RF93', 'RF96', 'RF99', 'RF103', 'RF61', 'RF12', 'RF13', 'RF14', 'RF28', 'RF18', 'RF17', 'RF15', 'RF16', 'RF38', 'RF39', 'RF73', 'RF74', 'RF75', 'RF80', 'RF92', 'RF95', 'RF98', 'RF101', 'RF77', 'RF2', 'RF76', 'RF91', 'RF94', 'RF97', 'RF100', 'RF19', 'RF21', 'RF22', 'RF23', 'RF24', 'RF40', 'RF44', 'RF48', 'RF49', 'RF50', 'RF51', 'RF25', 'RF26', 'RF27', 'RF64', 'RF47', 'PF199'];
	readonly caissAnswers = ['PF154', 'PF155', 'PF158', 'RF46', 'PF159', 'PF157'];
	readonly bankDataAndConsentAnswers = ['PF87', 'PF88', 'PF89', 'PF202'];
	readonly economicSituationAnswers = ['PF73', 'PF74', 'PF112', 'PF113', 'PF173', 'PF110', 'PF117', 'PF78', 'PF79', 'PF80', 'PF81', 'PF82', 'PF83', 'PF84', 'PF85', 'PF86', 'PF111', 'PF76', 'PF77'];
	readonly residenceAnswers = ['PF38', 'PF39', 'PF40', 'PF41', 'PF42', 'PF43', 'PF44', 'PF45', 'PF46', 'PF47', 'PF48', 'PF90', 'PF126', 'PF179', 'PF180', 'PF50', 'PF51', 'PF128', 'PF129', 'PF52', 'PF130', 'PF131', 'PF132', 'PF133', 'PF134', 'PF135', 'PF136', 'PF137', 'PF138', 'PF139', 'PF140', 'PF56', 'PF160', 'PF161', 'PF162', 'PF163', 'PF57', 'PF58', 'PF59', 'PF60', 'PF61', 'PF62', 'PF63', 'PF64', 'PF65', 'PF66', 'PF67', 'PF68', 'PF69', 'PF70', 'PF71', 'PF72'];
	readonly unityOfConvivenceAnswers = ['PF20', 'PF21', 'PF22', 'PF23', 'PF24', 'PF25', 'PF184', 'PF26', 'PF114', 'PF100', 'PF115', 'PF104', 'PF105', 'PF106', 'PF109', 'PF194', 'PF119', 'PF195', 'PF196', 'PF120', 'PF27', 'PF92', 'PF190', 'PF28', 'PF203', 'PF30', 'PF31', 'PF32', 'PF152', 'PF121', 'PF185', 'PF171', 'PF172', 'PF186'];
	readonly personalDataAnswers = ['PF1', 'PF2', 'PF3', 'PF4', 'PF5', 'PF6', 'PF182', 'PF7', 'PF9', 'PF10', 'PF11', 'PF197', 'PF198', 'PF101', 'PF102', 'PF103', 'PF127', 'PF8', 'PF12', 'PF13', 'PF14', 'PF15', 'PF16', 'PF17', 'PF168', 'PF183', 'PF170', 'PF164', 'PF165', 'PF18', 'PF206', 'PF207', 'PF19', 'PF91', 'PF36', 'PF37', 'PF191', 'PF122', 'PF144', 'PF145', 'PF189', 'PF146', 'PF188', 'PF187', 'PF201', 'PF148', 'PF149', 'PF150', 'PF151', 'PF124', 'PF192', 'PF193', 'PF125', 'PF53', 'PF54', 'PF55'];
	private readonly unicodeRegex = /[\u0080-\uFFFF]/g;
	private handleAnswer = (answer: Answer) => {
		answer = this.handleWhitespaces(answer, 'PF87');
		answer = this.handleWhitespaces(answer, 'PF54');
		answer = this.handleWhitespaces(answer, 'PF55');
		answer = this.handleWhitespaces(answer, 'MO1');
		answer = this.handleWhitespaces(answer, 'MO2');
		answer = this.handleWhitespaces(answer, 'MO17');
		answer = this.handleUnicodes(answer);
		return answer;
	}

	public handeAnswers = (answers: Answer[]) => answers.map(this.handleAnswer);
	private replaceRegexFromAnswer = (a: Answer, key: string, regex: RegExp) => (a.value = a.key === key ? a.value?.replace(regex, '') : a.value, a);
	private handleWhitespaces = (a: Answer, key: string) => this.replaceRegexFromAnswer(a, key, / /g);
	handleUnicode = (str: string) => str.replace(this.unicodeRegex, '_');

	private handleUnicodes = (a: Answer) => {
		const documentAnswers = [
			...this.documentAnswers,
			'PI13',
			'PI28',
		];
		const included = documentAnswers.includes(a.key);
		return included ? this.handleFileText(a) : a;
	};
	private handleFileText = (a: Answer) => {
		const { value } = a;
		if (typeof value === 'object') {
			value?.map((elem: any) => elem.name = this.handleUnicode(elem.name));
			a.value = value;
		}
		return a;
	};

}
